import { FormControl, TextField } from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';

const InsurerSection = ({ project, onChange }: SectionProps) => (
    <Section id='insurer-section'>
        <h5>Insurer Info</h5>
        <FormControl fullWidth>
            <TextField
                label='Insurer Name'
                name='insurerName'
                value={project.insurerName || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Adjuster Name'
                name='adjusterName'
                value={project.adjusterName || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Adjustor Contact Number'
                name='adjusterContactNumber'
                value={project.adjusterContactNumber || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Claim Number'
                name='claimNumber'
                value={project.claimNumber || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Claims Department Number'
                name='claimsDeptContactNumber'
                value={project.claimsDeptContactNumber || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Email'
                name='adjusterEmail'
                value={project.adjusterEmail || ''}
                onChange={onChange}
            />
        </FormControl>
    </Section>
);

export default InsurerSection;
