import {
  Grid,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Button,
  ClickAwayListener,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsContainer from '../SettingsComponents/SettingsContainer';
import { getOrganizationUsers } from 'actions/Users';
import LoggerWrapper from 'lib/Logger';
import User from 'lib/Models/User';
import SideMenu from '../SettingsComponents/SideMenu';
import UserContext from './UserContext';
import { ExpandMore } from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 70
      }
    },
    header: {
      font: 'normal normal bold 34px/20px Roboto',
      letterSpacing: 0.3,
      color: '#222222',
      padding: '20px 0px',
    },
    sideMenu: {
      zIndex: 4
    },
    subtitle: {
      paddingBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    closeMenuButton: {
      backgroundColor: '#FFF',
      width: '50px',
      height: '50px',
      padding: 0,
      position: 'absolute',
      top: 0,
      right: 0,
    }
  });

const emptyUser = {
  active: true,
  addOnId: null,
  allowedRoofSnapPlatforms: [''],
  allowedToEditEstimateItems: true,
  commissionRate: null,
  commissionType: null,
  contactNumber: '',
  deviceCount: null,
  firstName: '',
  isDeleted: false,
  lastAppHeartbeat: null,
  lastName: '',
  nearmapApiKey: null,
  organizationId: 0,
  overhead: null,
  paymentClientId: null,
  position: null,
  userId: 0,
  userName: '',
  userRoleIds: null,
}


const Users = ({ classes }: WithStyles<typeof styles>) => {
  const [users, setUsers] = useState<User[]>([])
  const [activeUsers, setActiveUsers] = useState<User[]>([])
  const [inactiveUsers, setInactiveUsers] = useState<User[]>([])
  const [user, setUser] = useState<User>(emptyUser)
  const [showActive, setShowActive] = useState<boolean>(true)
  const [menuOpen, setSideMenuOpen] = useState<boolean>(false)
  const { organization, currentUser, isAccountAdmin
  } = useSelector((state: any) => {
    const { organization, currentUser, currentUserRoles: { isAccountAdmin },
    } = state
    return {
      organization,
      currentUser,
      isAccountAdmin,
    }
  })
  const dispatch = useDispatch()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response: any = await dispatch(getOrganizationUsers(organization.id, 1, true))
        setUsers(response.response.resourceList)
        setActiveUsers(response.response.resourceList.filter((user: User) => user.active === true))
        setInactiveUsers(response.response.resourceList.filter((user: User) => user.active === false))
        set(response.response.resourceList.filter((user: User) => user.active === true)[0])
      } catch (err) {
        LoggerWrapper.log(err)
      }
    }
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  useEffect(() => {
    set(showActive ? activeUsers[0] : inactiveUsers[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActive])

  const setSelectedUser = (id: number) => {
    const user = users.find((user) => user.userId === id)
    if (user) {
      setUser(user)
    }
  }

  const set = (user: User) => {
    setUser(user)
  }

  return (
    <>
      {
        users &&
        <Grid item container direction='row' xs={12} alignContent="stretch" className={classes.root}>
          {
            !mobile &&
            <Grid item sm={3} className={classes.sideMenu}>
              <UserContext.Provider value={{ user, set }}>
                <SideMenu
                  setActiveInactive={setShowActive}
                  setSelectedUser={setSelectedUser}
                  users={showActive ? activeUsers : inactiveUsers}
                  currentUser={currentUser}
                  isAccountAdmin={isAccountAdmin}
                />
              </UserContext.Provider>
            </Grid>
          }
          {
            (mobile && users && isAccountAdmin) &&
            <Grid item container>
              <Button onClick={() => setSideMenuOpen(!menuOpen)}>
                <Typography>Team</Typography>
                <ExpandMore />
              </Button>
              {menuOpen &&
                <ClickAwayListener onClickAway={() => setSideMenuOpen(false)}>

                  <div style={{ position: 'relative' }}>
                    <UserContext.Provider value={{ user, set }}>
                      <SideMenu
                        setActiveInactive={setShowActive}
                        setSelectedUser={setSelectedUser}
                        users={showActive ? activeUsers : inactiveUsers}
                        currentUser={currentUser}
                        isAccountAdmin={isAccountAdmin}
                      />
                    </UserContext.Provider>
                  </div>
                </ClickAwayListener>

              }
            </Grid>
          }
          <Grid item sm={9} >
            <SettingsContainer
              title='Users'
              description='Manage users'
              sectionTitleStyle={classes.header}
              descriptionStyle={classes.subtitle}
            >
              <UserContext.Provider value={{ set, user }}>
                {user && users.length > 0 &&
                  <Typography>{user?.firstName}</Typography>
                }
              </UserContext.Provider>
            </SettingsContainer>
          </Grid>
        </Grid>
      }
    </>
  )


}

export default withStyles(styles)(Users);
