import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProjectDialog from './ProjectDialog';

const NotesTextField = styled(TextField)(() => ({
    marginBottom: 16,
    width: '100%',
}));

export interface NotesData {
    notes: string;
    notesContract: string;
    notesDelivery: string;
    notesInstallation: string;
    notesPreStart: string;
}

interface NotesDialogProps extends NotesData {
    open: boolean;
    setOpen: (open: boolean) => void;
    saveNotes: (notes: NotesData) => void;
}

const NotesDialog = (props: NotesDialogProps) => {
    const {
        open,
        setOpen,
        saveNotes,
        notes,
        notesContract,
        notesDelivery,
        notesInstallation,
        notesPreStart,
    } = props;
    const [generalNotes, setGeneralNotes] = useState(notes);
    const [contractNotes, setContractNotes] = useState(notesContract);
    const [deliveryNotes, setDeliveryNotes] = useState(notesDelivery);
    const [installationNotes, setInstallationNotes] =
        useState(notesInstallation);
    const [prestartNotes, setPrestartNotes] = useState(notesPreStart);
    const helperText: string = 'This items appears in your contract';
    useEffect(() => {
        setGeneralNotes(notes);
        setContractNotes(notesContract);
        setDeliveryNotes(notesDelivery);
        setInstallationNotes(notesInstallation);
        setPrestartNotes(notesPreStart);
    }, [notes, notesContract, notesDelivery, notesInstallation, notesPreStart]);
    const handleCancel = () => {
        setGeneralNotes(notes);
        setContractNotes(notesContract);
        setDeliveryNotes(notesDelivery);
        setInstallationNotes(notesInstallation);
        setPrestartNotes(notesPreStart);
        setOpen(false);
    };
    const handleSave = () => {
        setOpen(false);
        saveNotes({
            notes: generalNotes,
            notesContract: contractNotes,
            notesDelivery: deliveryNotes,
            notesInstallation: installationNotes,
            notesPreStart: prestartNotes,
        });
    };
    const Title = () => (
        <>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems={'center'}
            >
                Add Notes
                <IconButton onClick={handleCancel}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </>
    );
    return (
        <ProjectDialog
            open={open}
            title={<Title />}
            onCancel={handleCancel}
            onSave={handleSave}
        >
            <NotesTextField
                label='General Notes'
                value={generalNotes}
                onChange={(e) => setGeneralNotes(e.target.value)}
            />
            <NotesTextField
                label='Contract Notes'
                value={contractNotes}
                onChange={(e) => setContractNotes(e.target.value)}
                helperText={helperText}
            />
            <NotesTextField
                label='Delivery Notes'
                value={deliveryNotes}
                onChange={(e) => setDeliveryNotes(e.target.value)}
                helperText={helperText}
            />
            <NotesTextField
                label='Installation Notes'
                value={installationNotes}
                onChange={(e) => setInstallationNotes(e.target.value)}
            />
            <NotesTextField
                label='Pre-Start Notes'
                multiline
                rows={4}
                value={prestartNotes}
                onChange={(e) => setPrestartNotes(e.target.value)}
            />
        </ProjectDialog>
    );
};

export default NotesDialog;
