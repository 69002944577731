import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Fab from '@material-ui/core/Fab';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as WeMeasureForYou } from './measure-for-you.svg';
import { ReactComponent as MeasureYourself } from './measure-yourself.svg';
import { ReactComponent as ProfessionalDocuments } from './professional-documents.svg';
import { ReactComponent as WeWorkForYou } from './we-work-for-you.svg';
import Carousel from '../Carousel';

const styles = theme => ({
    image: {
        width: '100%',
        height: 300,
    },
    title: {
        fontWeight: 'bold',
    },
    progressIndicator: {
        marginLeft: theme.spacing(1),
    },
});

class IntroSlides extends Component {
    render() {
        const slides = [
            <React.Fragment>
                <WeMeasureForYou className={this.props.classes.image} />
                <Typography color="secondary" variant="h5" align="center" className={this.props.classes.title}>
                    We Measure For You
                </Typography>
                <Typography color="textSecondary" variant="body1" align="center">
                    Our Sketch Ordering Service, SketchOS, provides you with accurate measurements in two to four hours, and your first order is free!
                </Typography>
            </React.Fragment>,
            <React.Fragment>
                <MeasureYourself className={this.props.classes.image} />
                <Typography color="secondary" variant="h5" align="center" className={this.props.classes.title}>
                    Measure Yourself
                </Typography>
                <Typography color="textSecondary" variant="body1" align="center">
                    Our industry-leading measurement software lets you create accurate measurement reports on any device, and saves you time and trips to the job-site.
                </Typography>
            </React.Fragment>,
            <React.Fragment>
                <ProfessionalDocuments className={this.props.classes.image} />
                <Typography color="secondary" variant="h5" align="center" className={this.props.classes.title}>
                    Professional Documents
                </Typography>
                <Typography color="textSecondary" variant="body1" align="center">
                    Upload your company logo and create professional reports, compelling estimates, and signable contracts that you can print or share with your customers.
                </Typography>
            </React.Fragment>,
            <React.Fragment>
                <WeWorkForYou className={this.props.classes.image} />
                <Typography color="secondary" variant="h5" align="center" className={this.props.classes.title}>
                    We Work For You
                </Typography>
                <Typography color="textSecondary" variant="body1" align="center">
                    We’re here to help you succeed. Our team is available for support, online or by phone, and training is always free.
                </Typography>
            </React.Fragment>,
        ];

        return (
            <Carousel
                open={this.props.open}
                slides={slides}
                nextButton={
                    <IconButton>
                        <ChevronRight />
                    </IconButton>
                }
                backButton={
                    <IconButton>
                        <ChevronLeft />
                    </IconButton>
                }
                startButton={
                    <Fab
                        color="secondary"
                        variant="extended"
                        onClick={this.props.onDismissClick}
                        disabled={this.props.isLoading}
                        id="getting-started-button"
                    >
                        Get Started
                        {
                            this.props.isLoading &&
                            <CircularProgress
                                size={16}
                                color="inherit"
                                className={this.props.classes.progressIndicator}
                            />
                        }
                    </Fab>
                }
            />
        );
    }
}

IntroSlides.propTypes = {
    open: PropTypes.bool.isRequired,
    onDismissClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

IntroSlides.defaultProps = {
    isLoading: false,
};

export default withRouter(withStyles(styles)(withMobileDialog()(IntroSlides)));
