import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';
import Grow from '@material-ui/core/Grow';

const styles = theme => ({
    dialogContent: {
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            paddingBottom: 80,
        },
    },
    gridContainer: {
        height: '100%',
    },
    dialogActions: {
        justifyContent: 'center',
        borderTop: `solid 1px ${theme.palette.text.secondary}`,
        margin: 0,
    },
    stepper: {
        backgroundColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    stepperDotActive: {
        backgroundColor: theme.palette.secondary.A200,
    },
    getStartedButton: {
        position: 'absolute',
        bottom: 100,
        [theme.breakpoints.up('md')]: {
            bottom: 20,
        },
    },
});

class Carousel extends Component {
    state = {
        initialLoad: true,
        slide: 0,
        direction: 'right',
        showStartButton: false,
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open &&
            this.props.open === true) {
            this.autoAdvanceInterval = setInterval(this.goToNext, 5000);
        }
    }

    handleNextClick = () => {
        clearInterval(this.autoAdvanceInterval);
        this.goToNext();
    };

    goToNext = () => {
        if (this.state.slide === 3) {
            clearInterval(this.autoAdvanceInterval);
            return;
        }

        this.setState({
            slide: this.state.slide + 1,
            direction: 'left',
            initialLoad: false,
        }, () => {
            setTimeout(() => {
                if (this.state.slide === 3) {
                    this.setState({ showStartButton: true });
                }
            }, 1500);
        });
    }

    goToPrevious = () => {
        clearInterval(this.autoAdvanceInterval);
        this.setState({
            slide: this.state.slide - 1,
            direction: 'right',
            initialLoad: false,
        });
    }

    renderItem = (slide, index) => {
        if (this.state.initialLoad && index === 0) {
            return (
                <Grid key={index} item xs={12} md={10}>
                    {slide}
                </Grid>
            );
        }

        return (
            this.state.slide === index &&
            <Slide key={index} direction={this.state.direction} in={this.state.slide === index}>
                <Grid item xs={12} md={10}>
                    {slide}
                </Grid>
            </Slide>
        );
    }

    render() {
        return (
            <Dialog open={this.props.open} fullScreen={this.props.fullScreen}>
                <DialogContent className={this.props.classes.dialogContent}>
                    <Grid container justify="center" alignItems="center" className={this.props.classes.gridContainer}>
                        <Hidden smDown>
                            <Grid item md={1}>
                                {
                                    React.cloneElement(this.props.backButton, { onClick: this.goToPrevious, disabled: this.state.slide === 0 })
                                }
                            </Grid>
                        </Hidden>
                        {
                            this.props.slides.map(this.renderItem)
                        }
                        <Hidden smDown>
                            <Grid item md={1}>
                                {
                                    React.cloneElement(this.props.nextButton, { onClick: this.handleNextClick, disabled: this.state.slide === (this.props.slides.length - 1) })
                                }
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item xs={12}>
                                <MobileStepper
                                    steps={this.props.slides.length}
                                    position="static"
                                    activeStep={this.state.slide}
                                    classes={{
                                        root: this.props.classes.stepper,
                                        dotActive: this.props.classes.stepperDotActive,
                                    }}
                                />
                            </Grid>
                        </Hidden>

                        <Grow in={this.state.showStartButton} timeout={300}>
                            {React.cloneElement(this.props.startButton, { className: this.props.classes.getStartedButton })}
                        </Grow>
                    </Grid>
                </DialogContent>
                <Hidden mdUp>
                    <DialogActions
                        className={this.props.classes.dialogActions}
                    >
                        <MobileStepper
                            steps={this.props.slides.length}
                            position="static"
                            activeStep={this.state.slide}
                            classes={{
                                root: this.props.classes.stepper,
                                dotActive: this.props.classes.stepperDotActive,
                            }}
                            backButton={
                                React.cloneElement(this.props.backButton, { onClick: this.goToPrevious, disabled: this.state.slide === 0 })
                            }
                            nextButton={
                                React.cloneElement(this.props.nextButton, { onClick: this.handleNextClick, disabled: this.state.slide === (this.props.slides.length - 1) })
                            }
                        />
                    </DialogActions>
                </Hidden>
            </Dialog>
        );
    }
}

Carousel.propTypes = {
    open: PropTypes.bool.isRequired,
    backButton: PropTypes.node.isRequired,
    nextButton: PropTypes.node.isRequired,
    slides: PropTypes.arrayOf(PropTypes.node).isRequired,
    startButton: PropTypes.node,
};

Carousel.defaultProps = {
    startButton: null,
};

export default withStyles(styles)(withMobileDialog()(Carousel));
