import React, { ChangeEvent } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import Project from 'lib/Models/Project';
import formSections from './FormSections';

type ProjectDetailsFormProps = {
    project: Project;
    onChange?: (project: Project) => void;
};

const ProjectDetailsForm = ({ project, onChange }: ProjectDetailsFormProps) => {
    const handleChange = (
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<any>
    ) => {
        const outputProject = {
            ...project,
            [event.target.name]: event.target.value,
        };

        if (onChange) onChange(outputProject);
    };

    return project ? (
        <Box>
            <h5>Project</h5>
            {formSections.map((Section, i) => (
                <Section
                    key={`ProjectDetailsFormSection${i}`}
                    project={project}
                    onChange={handleChange}
                />
            ))}
        </Box>
    ) : (
        <Box />
    );
};

export default ProjectDetailsForm;
