import { Box, Typography } from '@mui/material';
import React from 'react';

type SectionTitleProps = {
    title: string;
};

const SectionTitle = ({ title }: SectionTitleProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    color: '#1D1B20',
                    fontFamily: 'Roboto',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '1.5rem' /* 150% */,
                    letterSpacing: '0.00938rem',
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default SectionTitle;
