import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ProjectDetailsForm from './ProjectDetailsForm.tsx';
import { getProjectById, updateProjectDetails } from '../../actions/ProjectActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import { handleMissingOffices } from '../../actions/Offices';
import PageToolbarActions, { ToolbarSaveButton } from '../NavigationComponents/PageToolbarActions';
import ProjectExportCsv from '../ProjectExportCsv/ProjectExportCsv.tsx';
import projectStatus from '../../lib/ProjectStatus';

const style = {
    root: {
        padding: 24,
    },
};

export class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            project: props.project,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadData();
    }

    handleSave = () => {
        if (!this.state.isValid) return;
        const { dispatch, history, project } = this.props;
        dispatch(updateProjectDetails(this.state.project)).then((response) => {
            if (response.error) {
                return dispatch(setReduxMessage('We were unable to save, please try again'));
            }
            history.push(`/projects/${project.id}`);
            return dispatch(setReduxMessage('The project was successfully updated'));
        });
    };
    loadData = async () => {
        const { dispatch, offices } = this.props;

        if (isEmpty(offices)) {
            await dispatch(handleMissingOffices());
        }

        await dispatch(getProjectById(this.props.match.params.id));
        this.setState({ project: this.props.project });
    };
    handleChange = (event) => {
        this.setState({
            project: {
                ...this.state.project,
                [event.target.name]: event.target.value,
            },
        });
    };
    validateForm = (isValid) => {
        this.setState({
            isValid,
        });
    };
    render() {
        const { offices } = this.props;
        const { project } = this.state;
        const { dispatch } = this.props;
        // if the project doesn't have a name, use the customer address
        const projectName = project.projectName ? project.projectName : project.customerAddress;
        return (
            <div style={style.root}>
                <PageToolbarActions key={project.id}>
                    <ProjectExportCsv projectId={project.id} projectName={projectName} />
                    <ToolbarSaveButton onClick={this.handleSave} tooltip="Save Details" />
                </PageToolbarActions>
                <ProjectDetailsForm
                    offices={offices}
                    statuses={Object.entries(projectStatus).map(status => ({
                        value: status[0],
                        label: status[1],
                    }))}
                    project={project}
                    onChange={this.handleChange}
                    validate={this.validateForm}
                    dispatch={dispatch}
                />
            </div>
        );
    }
}
ProjectDetails.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
    project: PropTypes.shape({
        id: PropTypes.string,
        projectName: PropTypes.string,
        officeId: PropTypes.number,
        projectStatus: PropTypes.string,
        customerName: PropTypes.string,
        customerAddress: PropTypes.string,
        customerCity: PropTypes.string,
        customerState: PropTypes.string,
        customerZip: PropTypes.string,
        customerPhone: PropTypes.string,
        customerPhone2: PropTypes.string,
        customerEmail: PropTypes.string,
        billingName: PropTypes.string,
        billingCompany: PropTypes.string,
        billingAddress: PropTypes.string,
        billingCity: PropTypes.string,
        billingState: PropTypes.string,
        billingZip: PropTypes.string,
        billingPhone: PropTypes.string,
        billingPhone2: PropTypes.string,
        billingEmail: PropTypes.string,
        insurerName: PropTypes.string,
        adjusterName: PropTypes.string,
        adjusterContactNumber: PropTypes.string,
        claimNumber: PropTypes.string,
        claimsDeptContactNumber: PropTypes.string,
        adjusterEmail: PropTypes.string,
    }).isRequired,
    offices: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

ProjectDetails.defaultProps = {
    offices: [],
};

const mapStateToProps = (state) => {
    const { projectHome, userOffices } = state;
    const { currentProject: project } = projectHome;
    const { data: offices } = userOffices;
    return {
        offices: offices.map(office => ({
            value: office.id,
            label: office.officeName,
        })),
        project,
    };
};

export default connect(mapStateToProps)(ProjectDetails);
