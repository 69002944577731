import React from 'react';
import { UserOfficeDropdown } from '@roofsnap';
import Project from 'lib/Models/Project';

type ProjectDetailsOfficeDropdownProps = {
    handleOfficeChange: (event: any) => void;
    project: Project;
};

const ProjectDetailsOfficeDropdown = (
    props: ProjectDetailsOfficeDropdownProps
) => {
    const { handleOfficeChange, project } = props;
    const { officeId } = project;

    return (
        <>
            <UserOfficeDropdown
                label='Office'
                value={officeId || ''}
                onChange={handleOfficeChange}
                variant='standard'
            />
        </>
    );
};

export default ProjectDetailsOfficeDropdown;
