import React from 'react';
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    styled,
} from '@mui/material';

const baseStyle = {
    borderRadius: 100,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 20,
    letterSpacing: '0.1px',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 24px',
    gap: 8,
    '& .MuiButton-label': {
        height: 20,
    },
};

const PrimaryButton = styled(MuiButton)({
    ...baseStyle,
    backgroundColor: '#056CF2',
    color: '#FFF',
    '&:hover': {
        backgroundColor: '#559DFB',
    },
    textTransform: 'none',
});
const SecondaryButton = styled(MuiButton)({
    ...baseStyle,
    '&:hover': {
        backgroundColor: '#F4FAFE',
    },
    color: '#056CF2',
    textTransform: 'none',
});

interface ButtonProps extends MuiButtonProps {
    customVariant?: 'md3-primary' | 'md3-secondary';
}

const Button: React.FC<ButtonProps> = (props) => {
    const { customVariant, ...rest } = props;
    if (customVariant === 'md3-secondary') {
        return <SecondaryButton {...rest} />;
    } else if (customVariant === 'md3-primary') {
        return <PrimaryButton {...rest} />;
    } else {
        return <MuiButton {...rest} />;
    }
};

export default Button;
