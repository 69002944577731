import React, { PropsWithChildren, ReactNode } from 'react';
import { Dialog, Button, DialogTitle } from '@UI';
import { DialogActions, DialogContent, Divider } from '@mui/material';

type ProjectDetailsFormDialogProps = PropsWithChildren<{
    title: ReactNode;
    open?: boolean;
    onCancel: () => void;
    onSave: () => void;
}>;

const ProjectDialog = ({
    title,
    children,
    open,
    onCancel,
    onSave,
}: ProjectDetailsFormDialogProps) => {
    return (
        <Dialog open={!!open}>
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent>{children}</DialogContent>
            <Divider />
            <DialogActions
                sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
            >
                <Button
                    customVariant='md3-secondary'
                    onClick={() => onCancel()}
                >
                    Cancel
                </Button>
                <Button customVariant='md3-primary' onClick={() => onSave()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectDialog;
