import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Redirect, __RouterContext as RouterContext } from 'react-router';
import { RouterToUrlQuery } from 'react-url-query';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import thunkMiddleware from 'redux-thunk';
import theme from 'theme';
import CreateMeasurementOrder from '@views/MeasurementOrder/CreateMeasurementOrder.tsx';
import CreateMeasurementOrderComplete from '@views/MeasurementOrder/CreateMeasurementOrderComplete.tsx';
import loggingMiddleware from './middleware/loggingMiddleware';
import googleAnalytics from './middleware/googleAnalytics';
import LoggerWrapper from './lib/Logger';
// import DebugRouter from './lib/util/DebugRouter';
import analytics from './middleware/analytics';
import profitwell from './middleware/profitwell';
import RoofSnapNavigationComponents from './components/NavigationComponents/RoofSnapNavigation';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Projects from './components/Projects/Projects';
import rootReducer from './reducers';
import App from './containers/App';
import api from './middleware/api';
import './styles.css';
import SketchComponentHost from './components/SketchComponentHost';
import LoadingSpinner from './components/LoadingSpinner';
import ReduxMessages from './components/ReduxMessages';
import SketchOS from './components/SketchOrders';
import SketchOrder from './components/SketchOrder';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PaymentDetails from './components/Activation/PaymentDetails';
import CompleteMessage from './components/Activation/CompleteMessage';
import ChangePassword from './components/ChangePassword';
import AppVersionChecker from './components/AppVersionChecker';
import Auth from './components/Auth/Auth.tsx';
import NoMatch from './components/NoMatch';
import BillingFailedSketchOrders from './components/BillingFailedSketchOrders';
import ProjectBoard from './components/ProjectBoard';
import Account from './containers/Account';
import featureFlags from './lib/FeatureFlags.ts';
import Home from './components/Home';
import RegisterNew from './components/RegisterNew';
import RegisterComplete from './components/RegisterComplete';
import SetPassword from './components/SetPassword';
import ConfirmAccountInformation from './components/ConfirmAccountInformation';
import ConfirmAccountInformationComplete from './components/ConfirmAccountInformationComplete';
import Onboarding from './components/Onboarding';
import FirstSketchOrder from './components/FirstSketchOrder';
import PublicDocumentDownload from './components/DocumentDownload/PublicDocumentDownload';
import Welcome from './components/Welcome/Welcome';
import PaygoPricingPage from './components/PaygoPricing/PaygoPricingPage';
import UsernameAlreadyRegistered from './components/UsernameAlreadyRegistered/UsernameAlreadyRegistered';
import ExpiredAccount from './components/ExpiredAccount/ExpiredAccount.tsx';

import OrderCompletedLandingPage from './components/OrderConfirmation/OrderCompletedLandingPage.tsx';
import SettingsPage from './components/Settings/SettingsPage.tsx';
import ESign from './components/Documents/ESign.tsx';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
            api,
            loggingMiddleware,
            LoggerWrapper.logRocketMiddleware(),
            analytics,
            profitwell,
            googleAnalytics
        )
    )
);

const rootElement = document.getElementById('root');

const mainStyle = () => {
    const muiToolbarStyle = theme.mixins.toolbar;
    const numberOfToolBars = 2;
    const width = window.innerWidth;
    const { orientation } = window.screen;

    if (width > 0 && orientation === 'landscape') {
        return {
            height: `calc(100% - ${
                muiToolbarStyle[
                    '@media (min-width:0px) and (orientation: landscape)'
                ].minHeight * numberOfToolBars
            }px)`,
        };
    }
    if (width > 600) {
        return {
            height: `calc(100% - ${
                muiToolbarStyle['@media (min-width:600px)'].minHeight *
                numberOfToolBars
            }px)`,
        };
    }

    return {
        height: `calc(100% - ${
            muiToolbarStyle.minHeight * numberOfToolBars
        }px)`,
    };
};

const style = {
    container: {
        height: '100%',
    },
    main: mainStyle(),
};

const ExternalRedirect = ({ url }) => {
    window.location.href = url;

    return null;
};

render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                {/* <DebugRouter> */}
                <App>
                    <RouterToUrlQuery routerContext={RouterContext}>
                        <div style={style.container}>
                            <LoadingSpinner />
                            <ReduxMessages />
                            <AppVersionChecker />
                            <Route component={RoofSnapNavigationComponents} />
                            <Route component={SketchComponentHost} />
                            <main style={style.main}>
                                <Switch>
                                    <Route
                                        exact
                                        path='/login'
                                        component={Auth}
                                    />
                                    <Route
                                        exact
                                        path='/welcome'
                                        component={Onboarding}
                                    />
                                    <Route
                                        path='/welcome-register'
                                        component={Welcome}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/'
                                        component={Home}
                                    />
                                    {/* The route directly below here was placed here instead of the Projects component
                                        because otherwise it would inherit the protection from ProtectedRoute and non roofsnap users need to access this */}
                                    <Route
                                        path='/projects/:projectId/documents/sign/:documentId'
                                        component={ESign}
                                    />
                                    <ProtectedRoute
                                        path='/projects'
                                        component={Projects}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/orders/create/complete'
                                        component={
                                            CreateMeasurementOrderComplete
                                        }
                                    />
                                    <ProtectedRoute
                                        sketchFeatureRequired
                                        exact
                                        path='/orders/create'
                                        component={CreateMeasurementOrder}
                                    />
                                    <Route exact path='/orders/v2/create'>
                                        <Redirect to='/orders/create' />
                                    </Route>
                                    <ProtectedRoute
                                        sketchFeatureRequired
                                        exact
                                        path='/orders'
                                        component={SketchOS}
                                    />
                                    <ProtectedRoute
                                        sketchFeatureRequired
                                        exact
                                        path='/orders/map'
                                        component={FirstSketchOrder}
                                    />
                                    <ProtectedRoute
                                        sketchFeatureRequired
                                        exact
                                        path='/orders/:sketchOrderId'
                                        component={SketchOrder}
                                    />
                                    <ProtectedRoute
                                        exact
                                        sketchFeatureRequired
                                        path='/billingfailedorders'
                                        component={BillingFailedSketchOrders}
                                    />
                                    <ProtectedRoute
                                        exact
                                        sketchFeatureRequired
                                        path='/billingfailedorders/:sketchOrderId'
                                        component={SketchOrder}
                                    />
                                    <Route
                                        exact
                                        path='/signup'
                                        component={RegisterNew}
                                    />
                                    <Route
                                        exact
                                        path='/username-already-registered'
                                        component={UsernameAlreadyRegistered}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/activate'
                                        component={PaymentDetails}
                                        activationWorkflow
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/activate/billinginformation'
                                        component={PaymentDetails}
                                        activationWorkflow
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/activate/complete'
                                        component={CompleteMessage}
                                        activationWorkflow
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/changepassword'
                                        component={ChangePassword}
                                    />
                                    <Route
                                        exact
                                        path='/resetpassword'
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        exact
                                        path='/resetpassword/:token'
                                        component={ResetPassword}
                                    />
                                    <Route
                                        exact
                                        path='/register'
                                        component={RegisterNew}
                                    />
                                    <Route
                                        exact
                                        path='/registernew'
                                        component={RegisterNew}
                                    />
                                    <Route
                                        exact
                                        path='/register/complete'
                                        component={() => <RegisterComplete />}
                                    />
                                    <Route
                                        exact
                                        path='/register/complete/:token'
                                        component={SetPassword}
                                    />
                                    <Route
                                        exact
                                        path='/account/confirm'
                                        component={ConfirmAccountInformation}
                                    />
                                    <Route
                                        exact
                                        path='/document/:projectId/:documentId/:renderingId/:sharedAccessSignatureUrl'
                                        component={PublicDocumentDownload}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/pricing'
                                        component={PaygoPricingPage}
                                    />
                                    <Route
                                        exact
                                        path='/expired'
                                        component={ExpiredAccount}
                                    />
                                    <ProtectedRoute
                                        path='/settings'
                                        component={SettingsPage}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/ordercompleted'
                                        component={OrderCompletedLandingPage}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/materials'
                                        component={() => (
                                            <ExternalRedirect
                                                url={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/materials`}
                                            />
                                        )}
                                    />

                                    <Route
                                        exact
                                        path='/account/confirm/complete'
                                        component={() => (
                                            <ConfirmAccountInformationComplete />
                                        )}
                                    />
                                    {featureFlags.projectBoard && (
                                        <ProtectedRoute
                                            exact
                                            path='/project-board'
                                            component={ProjectBoard}
                                        />
                                    )}
                                    {featureFlags.projectBoard && (
                                        <ProtectedRoute
                                            exact
                                            path='/project-board/:id'
                                            component={ProjectBoard}
                                        />
                                    )}
                                    {featureFlags.accountManagement && (
                                        <ProtectedRoute
                                            exact
                                            path='/account'
                                            component={Account}
                                        />
                                    )}
                                    {!featureFlags.accountManagement && (
                                        <ProtectedRoute
                                            exact
                                            path='/account'
                                            component={() => (
                                                <ExternalRedirect
                                                    url={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/account`}
                                                />
                                            )}
                                        />
                                    )}
                                    {/* Always ensure this route is last!!! */}
                                    <Route component={NoMatch} />
                                </Switch>
                            </main>
                        </div>
                    </RouterToUrlQuery>
                </App>
            </BrowserRouter>
        </MuiThemeProvider>
    </Provider>,
    rootElement
);

// expose store when Cypress runs
if (window.Cypress) {
    window.store = store;
}
