import { FormControl, TextField } from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';

const BillingSection = ({ project, onChange }: SectionProps) => (
    <Section id='billing-section'>
        <h5>Billing Info</h5>
        <FormControl fullWidth>
            <TextField
                label='Billing Name'
                name='billingName'
                value={project.billingName || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing Address'
                name='billingAddress'
                value={project.billingAddress || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing City'
                name='billingCity'
                value={project.billingCity || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing State'
                name='billingState'
                value={project.billingState || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing Zipcode'
                name='billingZip'
                value={project.billingZip || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing Phone 1'
                name='billingPhone'
                value={project.billingPhone || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing Phone 2'
                name='billingPhone2'
                value={project.billingPhone2 || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Billing Email'
                name='billingEmail'
                value={project.billingEmail || ''}
                onChange={onChange}
            />
        </FormControl>
    </Section>
);

export default BillingSection;
