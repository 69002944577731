import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import { getProjectById, updateProjectDetails } from 'actions/ProjectActions';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import NotesDialog, { NotesData } from './V2/NotesDialog';
import ProjectDetails from './V2/ProjectDetails';
import Project from 'lib/Models/Project';
import Estimate from './V2/Estimate';

// TODO: Rename this to ProjectHome as part of the cleanup after Easier Estimates is complete
type ProjectParams = {
    projectId: string;
};
export const ProjectHomeV2: React.FC = () => {
    const { projectId } = useParams<ProjectParams>();
    const dispatch = useDispatch();
    const { currentProject } = useSelector(
        (store: RoofSnapState) => store.project
    );
    const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
    const handleNotesClick = () => {
        setOpenNotesDialog(true);
    };
    const handleSaveNotes = (notes: NotesData) => {
        let updatedProject = { ...currentProject, ...notes };
        dispatch(updateProjectDetails(updatedProject));
    };
    const isEmptyProject = (project: Project) => {
        return Object.keys(project).length === 0;
    };
    React.useEffect(() => {
        if (!currentProject || isEmptyProject(currentProject)) {
            dispatch(getProjectById(projectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item xs={12} sm={10} lg={8}>
                    <Box p={2}>
                        <ProjectDetails
                            project={currentProject}
                            addNotesClicked={handleNotesClick}
                        ></ProjectDetails>
                    </Box>
                    <Estimate />
                </Grid>
            </Grid>
            <NotesDialog
                open={openNotesDialog}
                setOpen={setOpenNotesDialog}
                saveNotes={handleSaveNotes}
                notes={currentProject.notes}
                notesContract={currentProject.notesContract}
                notesDelivery={currentProject.notesDelivery}
                notesInstallation={currentProject.notesInstallation}
                notesPreStart={currentProject.notesPreStart}
            ></NotesDialog>
        </>
    );
};
