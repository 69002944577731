export const SET_PAGETOOLBAR_BUTTONS = 'SET_PAGETOOLBAR_BUTTONS';
export const SET_PAGETOOLBAR_ELEMENTS = 'SET_PAGETOOLBAR_ELEMENTS';
export const SET_PAGETOOLBAR_NAVTABS = 'SET_PAGETOOLBAR_NAV_TABS';
export const SET_PAGETOOLBAR_ACTIVE_NAV_TAB = 'SET_PAGETOOLBAR_ACTIVE_NAV_TAB';
export const SET_PAGETOOLBAR_SAVE_HANDLER = 'SET_PAGETOOLBAR_SAVE_HANDLER';
export const SET_PAGETOOLBAR_CANCEL_HANDLER = 'SET_PAGETOOLBAR_CANCEL_HANDLER';
export const SET_PAGETOOLBAR_DELETE_HANDLER = 'SET_PAGETOOLBAR_DELETE_HANDLER';
export const RESET_PAGETOOLBAR = 'RESET_PAGETOOLBAR';
export const TOGGLE_TOOLBAR = 'TOGGLE_TOOLBAR';

export function setPageToolbarButtons(buttons) {
    return {
        type: SET_PAGETOOLBAR_BUTTONS,
        buttons,
    };
}

export function setPageToolbarElements(elements) {
    return {
        type: SET_PAGETOOLBAR_ELEMENTS,
        elements,
    };
}

export function setPageToolbarNavTabs(navTabs) {
    return {
        type: SET_PAGETOOLBAR_NAVTABS,
        navTabs,
    };
}

export function setPageToolBarActiveNavTab(activeTabIndex) {
    return {
        type: SET_PAGETOOLBAR_ACTIVE_NAV_TAB,
        activeTabIndex,
    };
}

export function resetPageToolbar() {
    return {
        type: RESET_PAGETOOLBAR,
    };
}

export function toggleToolbar() {
    return {
        type: TOGGLE_TOOLBAR,
    };
}
