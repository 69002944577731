import React, { useState } from 'react';
import { ButtonProps, Button, Box, ButtonGroup } from '@mui/material';

type FormSection =
    | 'project-section'
    | 'customer-section'
    | 'billing-section'
    | 'insurer-section';

const ProjectFormNavigation = () => {
    const [activeSection, setActiveSection] =
        useState<FormSection>('project-section');

    const NavButton = ({
        section,
        ...rest
    }: { section: FormSection } & ButtonProps) => {
        return (
            <Button
                {...rest}
                href={`#${section}`}
                color={activeSection === section ? 'primary' : undefined}
                onClick={() => setActiveSection(section)}
            />
        );
    };

    return (
        <Box sx={{ mt: '1.5rem' }}>
            <ButtonGroup color='secondary'>
                <NavButton section='project-section'>Project</NavButton>
                <NavButton section='customer-section'>Customer</NavButton>
                <NavButton section='billing-section'>Billing</NavButton>
                <NavButton section='insurer-section'>Insurer</NavButton>
            </ButtonGroup>
        </Box>
    );
};

export default ProjectFormNavigation;
