import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Popover, Grid, Typography } from '@material-ui/core';
import SketchOrder from 'lib/Models/SketchOrder';
import NearmapOrder from 'lib/Models/NearmapOrder';
import { getDocuments } from 'actions/documentsActions';
import { downloadDocument } from 'actions/documentActions';
import { createDocumentSharedAccessToken } from 'actions/documentSharedAccessTokenActions';
import DocumentCategoryNames from 'lib/DocumentsCategories';
import { changeSketchOrderStatus } from 'actions/SketchOrderActions';
import { setReduxMessage } from 'actions/ReduxMessagesActions';
import LoggerWrapper from 'lib/Logger';
import { OrderTypes } from './OrderTypes';
import { SketchOrderStatus } from 'lib';

interface OptionsPopoverProps extends RouteComponentProps {
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    order: SketchOrder | NearmapOrder;
    orderType: OrderTypes;
    setUpdateModalOpen?: (isOpen: boolean) => void;
    onOrderCancelled?: (response: SketchOrder) => void;
}

const OptionsPopover = (props: OptionsPopoverProps) => {
    const [orderDocuments, setDocuments] = useState<any>(null);
    const [status, setStatus] = useState<string>('Pending');
    const open = Boolean(props.anchorEl);
    const popoverId = open ? 'popover-id' : undefined;
    const dispatch = useDispatch();
    const { order, orderType } = props;
    const isSketchOrder = orderType === OrderTypes.Sketch;
    const isNearmapOrder = orderType === OrderTypes.Nearmap;

    useEffect(() => {
        if (isSketchOrder) {
            setStatus((order as SketchOrder).sketchOrderStatus);
        } else {
            setStatus((order as NearmapOrder).status ?? 'Pending');
        }
    }, [order, isSketchOrder, dispatch]);

    const goToSketchOrder = (id: number | string) => {
        const route =
            typeof id === 'string' ? `/projects/${id}` : `/orders/${id}`;
        props.history.push(route);
    };

    const downloadSketchOrderReport = async () => {
        const getSketchOrderDocuments = async () => {
            try {
                const response: any = await dispatch(
                    getDocuments(order.projectId)
                );
                setDocuments(response.documents);
            } catch (error) {
                LoggerWrapper.log(error);
            }
        };
        await getSketchOrderDocuments();
        const sketchOrderReportDocument = orderDocuments.filter(
            (d: any) =>
                d.template.templateCategory.name ===
                DocumentCategoryNames.SketchOrderReport
        )[0];
        try {
            const response: any = await dispatch(
                createDocumentSharedAccessToken(
                    sketchOrderReportDocument.id,
                    sketchOrderReportDocument.renderingId
                )
            );
            dispatch(
                downloadDocument(
                    response.response.sharedAccessSignatureUrl,
                    sketchOrderReportDocument.renderingFileName
                )
            );
        } catch (error) {
            LoggerWrapper.log(error);
        }
    };

    const cancelSketchOrder = async (
        id: number | string,
        version: string
    ): Promise<void> => {
        try {
            const response: any = await dispatch(
                changeSketchOrderStatus(id, version, 'Cancelled')
            );
            dispatch(setReduxMessage('Order cancelled.'));
            props.onOrderCancelled?.(response.response);
        } catch {
            dispatch(
                setReduxMessage(
                    'There was a problem trying to cancel the order.'
                )
            );
        }
    };

    const viewDetails = {
        text: isSketchOrder ? 'VIEW DETAILS' : 'GO TO PROJECT',
        onClick: () => {
            if (order) {
                const orderId = 'id' in order ? order.id : order.projectId;
                goToSketchOrder(orderId);
            }
        },
    };
    const updateOrder = {
        text: 'UPDATE ORDER',
        onClick: () => props.setUpdateModalOpen?.(true),
    };
    const downloadOrder = {
        text: 'DOWNLOAD',
        onClick: () => downloadSketchOrderReport(),
    };
    const cancelOrder = {
        text: 'CANCEL ORDER',
        onClick: (): Promise<void> | void => {
            if (isSketchOrder) {
                cancelSketchOrder(
                    (order as SketchOrder).id,
                    (order as SketchOrder).version
                );
            }
        },
    };

    type SketchOrderAction = {
        text: string;
        onClick: () => void;
    };

    type ButtonsByStatusType = {
        [key in SketchOrderStatus]: SketchOrderAction[];
    };

    const ButtonsByStatus: ButtonsByStatusType = {
        [SketchOrderStatus.Pending]: [viewDetails, updateOrder, cancelOrder],
        [SketchOrderStatus.InProgress]: [viewDetails, updateOrder],
        [SketchOrderStatus.Incomplete]: [viewDetails, updateOrder, cancelOrder],
        [SketchOrderStatus.BillingFailed]: [
            viewDetails,
            updateOrder,
            cancelOrder,
        ],
        [SketchOrderStatus.Cancelled]: [viewDetails],
        [SketchOrderStatus.InReview]: [viewDetails],
        [SketchOrderStatus.PendingReview]: [viewDetails],
        [SketchOrderStatus.PendingRejection]: [viewDetails],
        [SketchOrderStatus.Complete]: [viewDetails, downloadOrder],
        [SketchOrderStatus.Billed]: [viewDetails, downloadOrder],
        [SketchOrderStatus.Queued]: [viewDetails],
        [SketchOrderStatus.Exception]: [viewDetails, updateOrder],
        [SketchOrderStatus.RevisionRequested]: [viewDetails],
    };
    return (
        <Grid>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        width: 240,
                    },
                }}
            >
                <div style={{ padding: '8px' }}>
                    {
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={1}
                            direction='column'
                        >
                            {isSketchOrder &&
                                ButtonsByStatus[
                                    status as SketchOrderStatus
                                ].map((button) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            alignContent='center'
                                            onClick={button.onClick}
                                            style={{ cursor: 'pointer' }}
                                            data-cy='popover-button'
                                        >
                                            <Typography>
                                                {button.text}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            {isNearmapOrder && (
                                <Grid
                                    item
                                    xs={12}
                                    alignContent='center'
                                    onClick={viewDetails.onClick}
                                    style={{ cursor: 'pointer' }}
                                    data-cy='popover-button'
                                >
                                    <Typography>{viewDetails.text}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    }
                </div>
            </Popover>
        </Grid>
    );
};

export default withRouter(OptionsPopover);
