import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { CALL_API } from '../middleware/api';
import httpRequestMethod from '../middleware/httpRequestMethods';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const GET_PROJECT_IMAGES_REQUEST = 'GET_PROJECT_IMAGES_REQUEST';
export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';
export const GET_PROJECT_IMAGES_FAILURE = 'GET_PROJECT_IMAGES_FAILURE';

export const GET_PROJECT_IMAGE_REQUEST = 'GET_PROJECT_IMAGE_REQUEST';
export const GET_PROJECT_IMAGE_SUCCESS = 'GET_PROJECT_IMAGE_SUCCESS';
export const GET_PROJECT_IMAGE_FAILURE = 'GET_PROJECT_IMAGE_FAILURE';

export const DELETE_PROJECT_IMAGE_REQUEST = 'DELETE_PROJECT_IMAGE_REQUEST';
export const DELETE_PROJECT_IMAGE_SUCCESS = 'DELETE_PROJECT_IMAGE_SUCCESS';
export const DELETE_PROJECT_IMAGE_FAILURE = 'DELETE_PROJECT_IMAGE_FAILURE';

export const SELECT_IMAGE = 'SELECT_IMAGE';

export const CREATE_PROJECT_IMAGE_REQUEST = 'CREATE_PROJECT_IMAGE_REQUEST';
export const CREATE_PROJECT_IMAGE_SUCCESS = 'CREATE_PROJECT_IMAGE_SUCCESS';
export const CREATE_PROJECT_IMAGE_FAILURE = 'CREATE_PROJECT_IMAGE_FAILURE';

const fetchProjectImages = (projectId, pageNumber = 1) => ({
    [CALL_API]: {
        types: [
            GET_PROJECT_IMAGES_REQUEST,
            GET_PROJECT_IMAGES_SUCCESS,
            GET_PROJECT_IMAGES_FAILURE,
        ],
        endpoint: `projects/${projectId}/projectimages?page=${pageNumber}`,
        method: httpRequestMethod.GET,
    },
});

const fetchProjectImageById = (projectId, imageId) => ({
    [CALL_API]: {
        types: [
            GET_PROJECT_IMAGE_REQUEST,
            GET_PROJECT_IMAGE_SUCCESS,
            GET_PROJECT_IMAGE_FAILURE,
        ],
        endpoint: `projects/${projectId}/projectimages/${imageId}`,
        method: httpRequestMethod.GET,
    },
});

export const deleteImage = image => ({
    [CALL_API]: {
        types: [
            DELETE_PROJECT_IMAGE_REQUEST,
            DELETE_PROJECT_IMAGE_SUCCESS,
            DELETE_PROJECT_IMAGE_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${image.projectId}/projectimages/${image.id}`,
        method: httpRequestMethod.DELETE,
    },
});

export const selectImage = image => ({
    type: SELECT_IMAGE,
    image,
});

export function showNextImage() {
    return (dispatch, getState) => {
        const {
            images,
            selectedImage,
            totalPages,
            projectId,
            currentPage,
        } = getState().projectImages;
        const currentIndex = findIndex(images, selectedImage);
        if (currentIndex === images.length - 1) {
            if (totalPages === 1) {
                return dispatch(selectImage(images[0]));
            }
            const pageNumber = currentPage === totalPages ? 1 : currentPage + 1;
            return dispatch(fetchProjectImages(projectId, pageNumber));
        }
        return dispatch(selectImage(images[currentIndex + 1]));
    };
}

export function showPreviousImage() {
    return (dispatch, getState) => {
        const {
            images,
            selectedImage,
            totalPages,
            projectId,
            currentPage,
        } = getState().projectImages;
        const currentIndex = findIndex(images, selectedImage);
        if (currentIndex === 0) {
            if (totalPages === 1) {
                return dispatch(selectImage(images[images.length - 1]));
            }
            const pageNumber = currentPage === 1 ? totalPages : currentPage - 1;
            return dispatch(fetchProjectImages(projectId, pageNumber)).then(data =>
                dispatch(selectImage(data.response.resourceList[
                    data.response.resourceList.length - 1
                ])));
        }
        return dispatch(selectImage(images[currentIndex - 1]));
    };
}

export function getProjectImages(projectId, pageNumber = 1) {
    return dispatch => dispatch(fetchProjectImages(projectId, pageNumber));
}

export function getImageById(projectId, imageId) {
    return (dispatch, getState) => {
        const { projectImages } = getState();
        return isEmpty(projectImages.selectedImage)
            ? dispatch(fetchProjectImageById(projectId, imageId))
            : projectImages.selectedImage;
    };
}

const fetchCreateProjectImage = (projectId, projectImage) => ({
    [CALL_API]: {
        types: [CREATE_PROJECT_IMAGE_REQUEST, CREATE_PROJECT_IMAGE_SUCCESS, CREATE_PROJECT_IMAGE_FAILURE],
        endpoint: `projects/${projectId}/projectimages`,
        method: httpRequestMethod.POST,
        body: projectImage,
    },
});

export const createProjectImage = (projectId, projectImage) =>
    dispatch =>
        dispatch(fetchCreateProjectImage(projectId, projectImage))
            .then((result) => {
                if (result.error) {
                    return result.error;
                }
                return dispatch(fetchProjectImages(projectId));
            });
