import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImageGrid from './ImageGrid';
import ProjectImageImport from './ProjectImageImport';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Pager from '../Pager/Pager.tsx';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import {
    getProjectImages,
    selectImage,
    deleteImage,
} from '../../actions/ProjectImagesActions';
import './ProjectImages.css';

const style = {
    root: {
        padding: 24,
    },
};

class ProjectImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getProjectImages(
            this.props.match.params.id,
            this.props.currentPage,
        ));
    }

    handleImageSelection = (image) => {
        this.props.dispatch(selectImage(image));
    };

    handleOpenDialog = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete = () => {
        this.props
            .dispatch(deleteImage(this.props.selectedImage))
            .then((response) => {
                if (response.error) {
                    this.handleClose();
                } else {
                    this.handleClose();
                    this.props.dispatch(setReduxMessage('The image was successfully deleted'));
                    this.props.dispatch(getProjectImages(
                        this.props.match.params.id,
                        this.props.currentPage,
                    ));
                }
            });
    };
    pagerRight = () => {
        this.props.dispatch(getProjectImages(
            this.props.match.params.id,
            this.props.currentPage + 1,
        ));
    };

    pagerLeft = () => {
        this.props.dispatch(getProjectImages(
            this.props.match.params.id,
            this.props.currentPage - 1,
        ));
    };
    render() {
        const {
            images, totalPages, currentPage,
        } = this.props;
        return (
            <div style={style.root}>
                <Pager
                    className="pager"
                    totalPages={totalPages}
                    currentPage={currentPage}
                    nextClick={this.pagerRight}
                    prevClick={this.pagerLeft}
                />
                <div className="gridList">
                    {images.length > 0 ? (
                        images.map(item => (
                            <ImageGrid
                                key={item.id}
                                image={item}
                                selectImage={this.handleImageSelection}
                                deleteClick={this.handleOpenDialog}
                            />
                        ))
                    ) : (
                        <div>You have no images for this project</div>
                    )}
                </div>
                <ConfirmationModal
                    title="Delete Image"
                    description="Are you sure you want to delete this image?"
                    handleConfirm={this.handleDelete}
                    confirmText="Delete"
                    isOpen={this.state.open}
                    handleClose={this.handleClose}
                />
                <ProjectImageImport projectId={this.props.match.params.id} />
            </div>
        );
    }
}

const ProjectImagesShape = {
    imageUrl: PropTypes.string,
    id: PropTypes.string,
    projectId: PropTypes.string,
};
/* eslint-disable react/forbid-prop-types */
ProjectImages.propTypes = {
    match: PropTypes.object.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    dispatch: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    selectedImage: PropTypes.shape(ProjectImagesShape),
};

ProjectImages.defaultProps = {
    selectedImage: {},
};

const mapState = state => ({
    ...state.projectImages,
});

export default connect(mapState)(ProjectImages);
