import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        maxWidth: 530,
        height: '100vh',
        width: '100%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    welcomeImage: {
        width: '100%',
        marginBottom: theme.spacing(8),
    },
});

class WelcomeToRoofSnap extends Component {
    state = {
        expandText: false,
        showText: false,
    };

    componentDidMount() {
        this.showWelcome();
    }

    showWelcome = () => {
        setTimeout(() => {
            this.setState({
                expandText: true,
            }, () => {
                setTimeout(() => {
                    this.setState({ showText: true });
                }, 800);
            });
        }, 1000);
    };

    render() {
        return (
            <Fade in={this.props.in} timeout={1000}>
                <Grid container spacing={1} justify="center" alignItems="center" className={this.props.classes.root}>
                    <Grid item xs={12}>
                        <img
                            src="/assets/welcome.jpeg"
                            alt="Documents"
                            className={this.props.classes.welcomeImage}
                        />
                        <Collapse in={this.state.expandText} timeout={800}>
                            <Fade in={this.state.showText} timeout={200}>
                                <Typography variant="h4" align="center" color="secondary">Welcome to RoofSnap!</Typography>
                            </Fade>
                        </Collapse>
                    </Grid>
                </Grid>
            </Fade>
        );
    }
}

WelcomeToRoofSnap.propTypes = {
    in: PropTypes.bool.isRequired,
};

export default withStyles(styles)(WelcomeToRoofSnap);
