import React from 'react';
import Chip from '@material-ui/core/Chip';
import { amber } from '@material-ui/core/colors';
import TimelapseIcon from '@material-ui/icons/Timelapse';

const ToolbarPendingSketchOrdersChip = (props) => {
    const { inProgressSketchOrders } = props;
    const composeLabel = () => `${inProgressSketchOrders} ${inProgressSketchOrders > 1 ? 'Orders' : 'Order'} In Progress`;
    return (
        <Chip
            label={composeLabel()}
            icon={<TimelapseIcon style={{ fill: '#ffffff' }} />}
            style={{
                color: '#ffffff',
                backgroundColor: amber[500],
            }}
            onClick={props.onClick}
        />
    );
};

export default ToolbarPendingSketchOrdersChip;
