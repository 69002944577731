import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ImageImportStyle = {
    fileInputStyle: {
        display: 'none',
    },
};

class ImageImport extends Component {
    constructor(props) {
        super(props);
        this.importImage = this.importImage.bind(this);
    }

    refInput = (input) => {
        this.props.imageImportRef = input;
    }

    importImage = (event) => {
        const imageInportInput = event.target;
        const imageFile = imageInportInput.files[0];
        const reader = new FileReader();

        reader.readAsBinaryString(imageFile);

        reader.onload = (onLoadEvent) => {
            const imageAsBinaryString = onLoadEvent.target.result;
            const image = btoa(imageAsBinaryString);

            this.props.callBack(image, imageFile.type);
        };
    };

    render() {
        return (
            <input
                ref={this.props.imageImportRef}
                name="importImage"
                type="file"
                style={ImageImportStyle.fileInputStyle}
                accept="image/*"
                onChange={this.importImage}
            />
        );
    }
}

ImageImport.propTypes = {
    imageImportRef: PropTypes.func.isRequired,
    callBack: PropTypes.func.isRequired,
};

export default ImageImport;
