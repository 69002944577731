import React from 'react';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import SectionTitle from './SectionTitle';
import SectionContainer from './SectionContainer';
import SectionInfoTitle from './SectionInfoTitle';
import SectionInfo from './SectionInfo';
import { ReactComponent as ChevronForwardIcon } from 'assets/ChevronForwardIcon.svg';

const Estimate = () => {
    return (
        <>
            <Box sx={{ margin: '32px 16px 16px 16px' }}>
                <SectionTitle title='Estimates'></SectionTitle>
            </Box>
            <SectionContainer>
                <Grid direction='row' container>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box width={'10.5625rem'} height={'10.5625rem'}>
                            <img src='/assets/Estimate.svg' alt='Estimate' />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={8}
                        md={10}
                        sx={{ paddingLeft: { xs: '0rem', md: '1.88rem' } }}
                    >
                        <Grid
                            container
                            direction='column'
                            sx={{ height: '100%' }}
                        >
                            <Grid item>
                                <Box
                                    sx={{
                                        height: '1.5rem',
                                        alignItems: 'flex-start',
                                        gap: '48.125rem',
                                        flexShrink: 0,
                                    }}
                                >
                                    <NewLabel>New</NewLabel>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        minHeight: '1.5rem',
                                        alignItems: 'flex-start',
                                        gap: '48.125rem',
                                        flexShrink: 0,
                                    }}
                                >
                                    <SectionInfoTitle>
                                        Get fast, easy & accurate Estimates
                                    </SectionInfoTitle>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexGrow: 1,
                                    paddingTop: '.75rem',
                                }}
                            >
                                <SectionInfo>
                                    New and improved professional estimates with
                                    good, better, best options. Get a jump start
                                    with templates and add your own items and
                                    prices. Show your customers and get a
                                    signature on the spot or via digital link.
                                </SectionInfo>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    paddingTop: { xs: '1.5rem', md: '.75rem' },
                                }}
                            >
                                <EstimateButton>View Sample</EstimateButton>
                                <Box sx={{ paddingRight: '0.69rem' }}></Box>
                                <EstimateButton isEstimate={true}>
                                    Try Estimates
                                    <ChevronForwardIcon />
                                </EstimateButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SectionContainer>
        </>
    );
};

type ButtonProps = {
    isEstimate?: boolean;
};

const EstimateButton = styled(Button)(({ isEstimate }: ButtonProps) => ({
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.25rem',
    letterSpacing: '0.00625rem',
    textTransform: 'none',
    borderRadius: '6.25rem',
    height: '2.5rem',
    padding: isEstimate
        ? '0.625rem 0.6875rem 0.625rem 1.375rem'
        : '0.625rem 1.375rem 0.625rem 1.375rem',
    border: isEstimate ? '' : '1px solid #D9D9D9',
    backgroundColor: isEstimate ? '#056CF2' : '#FFF',
    color: isEstimate ? '#FFF' : '#222',
    '&:hover': {
        backgroundColor: isEstimate ? '#559DFB' : '',
        color: isEstimate ? '#FFF' : '#',
    },
}));

const NewLabel = styled(Typography)({
    fontFamily: 'Roboto',
    fontSize: '0.6875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1rem' /* 145.455% */,
    letterSpacing: '0.03125rem',
    textTransform: 'uppercase',
    color: '#B3261E',
});

export default Estimate;
