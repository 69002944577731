import {
    Box,
    Button,
    createStyles,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import SelectedIcon from 'components/UI/Icons/SelectedIcon';
import gutterOption from 'lib/GutterOption';
import { AllowedOrderTypes } from 'lib/MeasurementOrders';
import React from 'react';

type GutterPanelSizeProps = {
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedGutterSize: number | undefined;
    selectedOrderType: AllowedOrderTypes;
    reportType: AllowedOrderTypes;
};

const useStyles = makeStyles(() =>
    createStyles({
        selectTitle: {
            color: '#222',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.25rem',
            letterSpacing: '0.00625rem',
            paddingTop: '10px',
            paddingBottom: '5px',
        },
        boxContainer: {
            marginTop: '.4rem',
            marginBottom: '.5rem',
        },
        buttonGutterPanel: {
            width: '7.3rem',
            textTransform: 'none',
            backgroundColor: '#FFF',
            border: '.5px #999 solid',
            color: '#555555',
            '&:hover': {
                backgroundColor: '#E0F0FF',
            },
        },
        buttonGutterPanelLeft: {
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
        },
        buttonGutterPanelRight: {
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '20px',
            borderTopRightRadius: '20px',
        },
        selectedGutterPanel: {
            backgroundColor: '#E0F0FF',
            border: '.5px #056CF2 solid',
            color: '#1F53B1',
            '&:hover': {
                backgroundColor: '#E0F0FF',
            },
        },
    })
);

const GutterPanelSize = ({
    selectedGutterSize,
    onGutterOptionsChange,
    selectedOrderType,
    reportType,
}: GutterPanelSizeProps) => {
    const classes = useStyles();

    const handleGutterPanelSize = (val: number | undefined) => {
        if (onGutterOptionsChange) {
            onGutterOptionsChange(val);
        }
    };

    const _orderType: AllowedOrderTypes = reportType;

    return (
        <Grid container direction='column'>
            <Typography className={classes.selectTitle}>
                Select gutter panel size
                <span style={{ color: '#B3261E' }}>*</span>
            </Typography>
            <Box className={classes.boxContainer}>
                {Object.keys(gutterOption).map((key) => {
                    const option = gutterOption[key];
                    let isReportTypeMatch: boolean =
                        selectedGutterSize === option.value &&
                        selectedOrderType === _orderType;
                    return (
                        option.value !== undefined && (
                            <Button
                                startIcon={
                                    isReportTypeMatch ? (
                                        <SelectedIcon />
                                    ) : undefined
                                }
                                key={option.value}
                                onClick={() =>
                                    handleGutterPanelSize(option.value)
                                }
                                className={classNames(
                                    classes.buttonGutterPanel,
                                    option.value === 5
                                        ? classes.buttonGutterPanelLeft
                                        : classes.buttonGutterPanelRight,
                                    isReportTypeMatch
                                        ? classes.selectedGutterPanel
                                        : undefined
                                )}
                            >
                                {option.label}
                            </Button>
                        )
                    );
                })}
            </Box>
        </Grid>
    );
};

export default GutterPanelSize;
