import {
    GET_ORGANIZATION_FEATURES_REQUEST,
    GET_ORGANIZATION_FEATURES_SUCCESS,
    GET_ORGANIZATION_FEATURES_FAILURE,
    GET_ORGANIZATION_FEATURES_REQUEST_BACKGROUND,
    GET_ORGANIZATION_FEATURES_SUCCESS_BACKGROUND,
    GET_ORGANIZATION_FEATURES_FAILURE_BACKGROUND,
} from '../actions/OrganizationFeatures';
import OrganizationFeatures from '../lib/OrganizationFeatures';
import organizationFeaturesHelper from '../lib/OrganizationFeaturesHelper';

function organizationFeatures(
    state = {
        addOns: false,
        estimating: false,
        sketchService: false,
        isFetching: false,
        allowSnappingGoogleImagery: false,
        rushOrdering: false,
        enableMetalRoof: false,
        enableGutters: false,
        enableEasierEstimates: false,
        enableEsignV2: false,
    },
    action: { type: string; response: { features: string[] } }
) {
    switch (action.type) {
        case GET_ORGANIZATION_FEATURES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case GET_ORGANIZATION_FEATURES_SUCCESS:
        case GET_ORGANIZATION_FEATURES_SUCCESS_BACKGROUND:
            return Object.assign({}, state, {
                addOns: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.AddOns
                ),
                estimating: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.Estimating
                ),
                sketchService: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.SketchService
                ),
                allowSnappingGoogleImagery: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.AllowSnappingGoogleImagery
                ),
                rushOrdering: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.RushOrdering
                ),
                enableMetalRoof: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.EnableMetalRoof
                ),
                enableGutters: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.EnableGutters
                ),
                enableEasierEstimates: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.EnableEasierEstimates
                ),
                enableEsignV2: organizationFeaturesHelper(
                    action.response.features,
                    OrganizationFeatures.EnableEsignV2
                ),
                isFetching: false,
            });
        case GET_ORGANIZATION_FEATURES_FAILURE:
            return Object.assign({}, state, {
                addOns: false,
                estimating: false,
                sketchService: false,
                isFetching: false,
                allowSnappingGoogleImagery: false,
                rushOrdering: false,
                enableMetalRoof: false,
                enableGutters: false,
                enableEasierEstimates: false,
                enableEsignV2: false,
            });
        case GET_ORGANIZATION_FEATURES_REQUEST_BACKGROUND:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case GET_ORGANIZATION_FEATURES_FAILURE_BACKGROUND:
            return Object.assign({}, state, {
                isFetching: false,
            });
        default:
            return state;
    }
}

export default organizationFeatures;
