import { ChangeEvent } from 'react';
import { Box, SelectChangeEvent, styled } from '@mui/material';
import Project from 'lib/Models/Project';

export type SectionProps = {
    project: Project;
    onChange: (
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<any>
    ) => void;
};

const Section = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.0625rem',
});

export default Section;
