import { Dialog as MuiDialog, styled } from '@mui/material';

const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '20px',
        overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {},
    '& .MuiDialogActions-root': {},
}));

export default Dialog;
