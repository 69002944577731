import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { emailValidation } from '../../lib/HelperFunctions.js';
import ProjectDetailsOfficeDropdown from './ProjectDetailsOfficeDropdown';
import { getProjectById } from 'actions/ProjectActions.js';
import Project from 'lib/Models/Project.js';

interface IProjectDetailsFormProps {
    project: Project,
    offices: {
        value: number,
        label: string,
    }[],
    statuses: {
            value: string,
            label: string,
        }[],
    classes: { //adding classes props here because class components cannot extend WithStyles due to incompatibility with hooks
        card: string,
        formControl: string,
        root: string,
    },
    onChange: any, //using any here because ChangeEventHandler and ChangeEvent types are incompatible in different places
    validate: Function,
    dispatch: any,
};

interface IProjectDetailsFormState {
    validator: {
        billingEmail: boolean,
        customerEmail: boolean,
        adjusterEmail: boolean,
    }
}

const styles = (theme: any) => ({ //using any here because class components cannot extend WithStyles due to incompatibility with hooks
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    card: {
        marginBottom: 20,
    },
});

class ProjectDetailsForm extends Component<IProjectDetailsFormProps, IProjectDetailsFormState> {
    constructor(props: IProjectDetailsFormProps) {
        super(props);
        this.state = {
            validator: {
                billingEmail: true,
                customerEmail: true,
                adjusterEmail: true,
            },
        };
    }
    getOfficeSelectItems = () =>
        this.props.offices.map((i) => ({ id: i.value, name: i.value }));
    handleEmailChange = (event: any) => {
        this.props.onChange(event);
        const emailIsValid = !emailValidation(event.target.value);
        this.props.validate(emailIsValid);
        this.setState({
            validator: {
                ...this.state.validator,
                [event.target.name]: emailIsValid,
            },
        });
    };
    handleOfficeChange = async (event: any) => {
        this.props.project.officeId = event.target.value;
        await this.props.dispatch(getProjectById(this.props.project.id));
    };
    render() {
        const { statuses, classes, project } = this.props;
        const { validator } = this.state;
        return (
            <Grid container alignItems='flex-start' justifyContent='center'>
                <Grid container style={{ maxWidth: 600 }}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='h2'
                                >
                                    Project info
                                </Typography>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='projectName'>
                                        Project name
                                    </InputLabel>
                                    <Input
                                        id='projectName'
                                        type='text'
                                        value={
                                            project.projectName
                                                ? project.projectName
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='projectName'
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <ProjectDetailsOfficeDropdown
                                        project={project}
                                        handleOfficeChange={
                                            this.handleOfficeChange
                                        }
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='projectStatus'>
                                        Status
                                    </InputLabel>
                                    <Select
                                        id='projectStatus'
                                        name='projectStatus'
                                        value={
                                            project.projectStatus
                                                ? project.projectStatus
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                    >
                                        {statuses.map((i) => (
                                            <MenuItem
                                                key={i.value}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='h2'
                                >
                                    Customer/job-site info
                                </Typography>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerName'>
                                        Name
                                    </InputLabel>
                                    <Input
                                        id='customerName'
                                        type='text'
                                        value={
                                            project.customerName
                                                ? project.customerName
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerName'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        shrink
                                        htmlFor='customerAddress'
                                    >
                                        Address
                                    </InputLabel>
                                    <Input
                                        id='customerAddress'
                                        type='text'
                                        value={
                                            project.customerAddress
                                                ? project.customerAddress
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerAddress'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerCity'>
                                        City
                                    </InputLabel>
                                    <Input
                                        id='customerCity'
                                        type='text'
                                        value={
                                            project.customerCity
                                                ? project.customerCity
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerCity'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerState'>
                                        State
                                    </InputLabel>
                                    <Input
                                        id='customerState'
                                        type='text'
                                        value={
                                            project.customerState
                                                ? project.customerState
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerState'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerZip'>
                                        Zip
                                    </InputLabel>
                                    <Input
                                        id='customerZip'
                                        type='text'
                                        value={
                                            project.customerZip
                                                ? project.customerZip
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerZip'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerPhone'>
                                        Phone
                                    </InputLabel>
                                    <Input
                                        id='customerPhone'
                                        type='text'
                                        value={
                                            project.customerPhone
                                                ? project.customerPhone
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerPhone'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='customerPhone2'>
                                        Phone 2
                                    </InputLabel>
                                    <Input
                                        id='customerPhone2'
                                        type='text'
                                        value={
                                            project.customerPhone2
                                                ? project.customerPhone2
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='customerPhone2'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                    error={!validator.customerEmail}
                                >
                                    <InputLabel shrink htmlFor='customerEmail'>
                                        Email
                                    </InputLabel>
                                    <Input
                                        id='customerEmail'
                                        type='text'
                                        value={
                                            project.customerEmail
                                                ? project.customerEmail
                                                : ''
                                        }
                                        onChange={this.handleEmailChange}
                                        name='customerEmail'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    {!validator.customerEmail && (
                                        <FormHelperText id='customerEmail'>
                                            Email is invalid
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='h2'
                                >
                                    Billing info
                                </Typography>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingName'>
                                        Billing name
                                    </InputLabel>
                                    <Input
                                        id='billingName'
                                        type='text'
                                        value={
                                            project.billingName
                                                ? project.billingName
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingName'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingAddress'>
                                        Billing address
                                    </InputLabel>
                                    <Input
                                        id='billingAddress'
                                        type='text'
                                        value={
                                            project.billingAddress
                                                ? project.billingAddress
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingAddress'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingCity'>
                                        Billing city
                                    </InputLabel>
                                    <Input
                                        id='billingCity'
                                        type='text'
                                        value={
                                            project.billingCity
                                                ? project.billingCity
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingCity'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingState'>
                                        Billing state
                                    </InputLabel>
                                    <Input
                                        id='billingState'
                                        type='text'
                                        value={
                                            project.billingState
                                                ? project.billingState
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingState'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingZip'>
                                        Billing zip
                                    </InputLabel>
                                    <Input
                                        id='billingZip'
                                        type='text'
                                        value={
                                            project.billingZip
                                                ? project.billingZip
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingZip'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingPhone'>
                                        Billing phone
                                    </InputLabel>
                                    <Input
                                        id='billingPhone'
                                        type='text'
                                        value={
                                            project.billingPhone
                                                ? project.billingPhone
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingPhone'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='billingPhone2'>
                                        Billing phone 2
                                    </InputLabel>
                                    <Input
                                        id='billingPhone2'
                                        type='text'
                                        value={
                                            project.billingPhone2
                                                ? project.billingPhone2
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='billingPhone2'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                    error={!validator.billingEmail}
                                >
                                    <InputLabel shrink htmlFor='billingEmail'>
                                        Billing email
                                    </InputLabel>
                                    <Input
                                        id='billingEmail'
                                        type='text'
                                        value={
                                            project.billingEmail
                                                ? project.billingEmail
                                                : ''
                                        }
                                        onChange={this.handleEmailChange}
                                        name='billingEmail'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    {!validator.billingEmail && (
                                        <FormHelperText id='billingEmail'>
                                            Email is invalid
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='h2'
                                >
                                    Insurer details
                                </Typography>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='insurerName'>
                                        Insurer name
                                    </InputLabel>
                                    <Input
                                        id='insurerName'
                                        type='text'
                                        value={
                                            project.insurerName
                                                ? project.insurerName
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='insurerName'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='adjusterName'>
                                        Adjuster name
                                    </InputLabel>
                                    <Input
                                        id='adjusterName'
                                        type='text'
                                        value={
                                            project.adjusterName
                                                ? project.adjusterName
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='adjusterName'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        shrink
                                        htmlFor='adjusterContactNumber'
                                    >
                                        Adjuster contact number
                                    </InputLabel>
                                    <Input
                                        id='adjusterContactNumber'
                                        type='text'
                                        value={
                                            project.adjusterContactNumber
                                                ? project.adjusterContactNumber
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='adjusterContactNumber'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel shrink htmlFor='claimNumber'>
                                        Claim number
                                    </InputLabel>
                                    <Input
                                        id='claimNumber'
                                        type='text'
                                        value={
                                            project.claimNumber
                                                ? project.claimNumber
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='claimNumber'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        shrink
                                        htmlFor='claimsDeptContactNumber'
                                    >
                                        Claims department number
                                    </InputLabel>
                                    <Input
                                        id='claimsDeptContactNumber'
                                        type='text'
                                        value={
                                            project.claimsDeptContactNumber
                                                ? project.claimsDeptContactNumber
                                                : ''
                                        }
                                        onChange={this.props.onChange}
                                        name='claimsDeptContactNumber'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                    error={!validator.adjusterEmail}
                                >
                                    <InputLabel shrink htmlFor='adjusterEmail'>
                                        Email
                                    </InputLabel>
                                    <Input
                                        id='adjusterEmail'
                                        type='text'
                                        value={
                                            project.adjusterEmail
                                                ? project.adjusterEmail
                                                : ''
                                        }
                                        onChange={this.handleEmailChange}
                                        name='adjusterEmail'
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    {!validator.adjusterEmail && (
                                        <FormHelperText id='adjusterEmail'>
                                            Email is invalid
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ProjectDetailsForm);
