import { FormControl, TextField } from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';

const CustomerSection = ({ project, onChange }: SectionProps) => (
    <Section id='customer-section'>
        <h5>Homeowner / Job-site Info</h5>
        <FormControl fullWidth>
            <TextField
                label='Name'
                name='customerName'
                value={project.customerName || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Address'
                name='customerAddress'
                value={project.customerAddress || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='City'
                name='customerCity'
                value={project.customerCity || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='State'
                name='customerState'
                value={project.customerState || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Zipcode'
                name='customerZip'
                value={project.customerZip || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Phone 1'
                name='customerPhone'
                value={project.customerPhone || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Phone 2'
                name='customerPhone2'
                value={project.customerPhone2 || ''}
                onChange={onChange}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                label='Email'
                name='customerEmail'
                value={project.customerEmail || ''}
                onChange={onChange}
            />
        </FormControl>
    </Section>
);

export default CustomerSection;
