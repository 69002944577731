import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import SavingsIcon from 'components/UI/Icons/SavingsIcon';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        textStyle: {
            fontSize: '0.875rem',
            fontStyle: 'normal',
            lineHeight: '1.25rem',
            fontWeight: 400,
            letterSpacing: '0.01563rem',
        },
    })
);

type OrderCreditInfoProps = {
    sketchOrdersAvailable: number;
    numberOfOrdersBeingPlaced: number;
};

const OrderCreditInfo = (props: OrderCreditInfoProps) => {
    const styles = useStyles();

    const totalFreeOrder =
        props.numberOfOrdersBeingPlaced <= props.sketchOrdersAvailable
            ? props.numberOfOrdersBeingPlaced
            : props.sketchOrdersAvailable;

    return (
        <Box
            display='flex'
            flexDirection='row'
            paddingTop={1.5}
            alignItems={'center'}
        >
            <SavingsIcon></SavingsIcon>
            <Typography
                style={{ flexGrow: 1, paddingLeft: 8 }}
                className={styles.textStyle}
            >
                {totalFreeOrder} of {props.sketchOrdersAvailable} credits will
                be applied to this order. When ordering multiple reports, only
                one report will be free. Rush Delivery not included.
            </Typography>
        </Box>
    );
};

export default OrderCreditInfo;
