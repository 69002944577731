import { DialogTitle as MuiDialogTitle, styled } from '@mui/material';

const DialogTitle = styled(MuiDialogTitle)(() => ({
    color: '#222',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
}));

export default DialogTitle;
