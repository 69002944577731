import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProjectImage } from '../../actions/ProjectImagesActions';
import ImageImport from '../ImageImport';
import FloatingActionButton from '../FloatingActionButton/FloatingActionButton';

class ProjectImageImport extends Component {
    createProjectImage = (image, mimeType) => {
        const newProjectImage = {
            image,
            mimeType,
        };
        this.props.dispatch(createProjectImage(this.props.projectId, newProjectImage));
    }

    selectImage = () => {
        this.imageImportComponent.click();
    }

    refImageImport = (component) => {
        this.imageImportComponent = component;
    }

    render() {
        return (
            <div>
                <FloatingActionButton onClick={this.selectImage} variant="extended" />
                <ImageImport
                    imageImportRef={this.refImageImport}
                    callBack={this.createProjectImage}
                />
            </div>
        );
    }
}

ProjectImageImport.propTypes = {
    dispatch: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default connect(undefined)(ProjectImageImport);
