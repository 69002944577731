import { InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { getOrganizationOffices } from 'actions/Offices';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type OrgOfficeDropdownProps = {} & SelectProps<any>;

const OrgOfficeDropdown = ({ ...rest }: OrgOfficeDropdownProps) => {
    const officeSelectId = useId();

    const { organizationOffices, organization } = useSelector(
        (state: RoofSnapState) => state
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (organizationOffices.data && organizationOffices.data.length) {
            return;
        }

        dispatch(getOrganizationOffices(organization.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization.id]);

    return (
        <>
            <InputLabel shrink htmlFor={officeSelectId}>
                {rest.label}
            </InputLabel>
            <Select {...rest}>
                {organizationOffices.data.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                        {office.officeName}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export default OrgOfficeDropdown;
